<template>
  <section class="px-6 text-lg max-w-4xl mx-auto">
    <h1>
      <span class="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
        Stefano Martella
      </span>
    </h1>
    <p class="mt-8 text-xl text-center text-gray-500 leading-8">
      Sono un front-end developer che durante l’esperienza
      lavorativa ha avuto modo di approfondire 
      ed utilizzare le tecnologie dedicate al back-end.
    </p>
  </section>
</template>

<script>
export default {
  name: 'Introduction',
  setup() {
    
  },
}
</script>