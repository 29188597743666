<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <footer class="bg-gradient-to-r bg-green-600">
    <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
      <div class="flex justify-center space-x-6 md:order-2">
        <a target="_blanck" v-for="item in navigation" :key="item.name" :href="item.href" class="text-gray-400 hover:text-gray-500">
          <span class="sr-only">{{ item.name }}</span>
          <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
        </a>
      </div>
      <div class="mt-8 md:mt-0 md:order-1">
        <p class="text-center text-base text-white">
          Stefano Martella - Developer
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
import { defineComponent, h } from 'vue'

const navigation = [
  {
    name: 'Twitter',
    href: 'https://twitter.com/Ste9614',
    icon: defineComponent({
      render: () =>
        h('svg', { fill: 'white', viewBox: '0 0 24 24' }, [
          h('path', {
            d:
              'M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84',
          }),
        ]),
    }),
  },
  {
    name: 'GitHub',
    href: 'https://github.com/StefanoMartella',
    icon: defineComponent({
      render: () =>
        h('svg', { fill: 'white', viewBox: '0 0 24 24' }, [
          h('path', {
            'fill-rule': 'evenodd',
            d:
              'M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z',
            'clip-rule': 'evenodd',
          }),
        ]),
    }),
  },
  {
    name: 'Stackoverflow',
    href: 'https://stackoverflow.com/users/9121838/stefano-martella',
    icon: defineComponent({
      render: () =>
        h('svg', { fill: 'white', viewBox: '0 0 384 512' }, [
          h('path', {
            'fill-rule': 'evenodd',
            d: 'M290.7 311L95 269.7 86.8 309l195.7 41zm51-87L188.2 95.7l-25.5 30.8 153.5 128.3zm-31.2 39.7L129.2 179l-16.7 36.5L293.7 300zM262 32l-32 24 119.3 160.3 32-24zm20.5 328h-200v39.7h200zm39.7 80H42.7V320h-40v160h359.5V320h-40z'
          })
        ])
    })
  },
  {
    name: 'Linkedin',
    href: 'https://www.linkedin.com/in/stefano-martella/',
    icon: defineComponent({
      render: () =>
        h('svg', { fill: 'white', viewBox: '0 0 384 512' }, [
          h('path', {
            'fill-rule': 'evenodd',
            d: 'M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z'
          })
        ])
    })
  }
]

export default {
  name: 'Footer',
  setup() {
    return {
      navigation,
    }
  },
}
</script>
