<template>
  <section class="px-6 max-w-4xl mx-auto my-12">
    <div class="mb-8">
      <h2 class="text-lg leading-8 font-medium text-gray-700">
        Il mio percorso
      </h2>
    </div>
    <ul role="list" class="-mb-8">
      <li v-for="(event, eventIdx) in timeline" :key="event.id">
        <div class="relative pb-8">
          <span v-if="(eventIdx !== timeline.length - 1)" class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
          <div class="relative flex space-x-3">
            <div class="flex items-center">
              <span :class="[event.iconBackground, 'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white']">
                <component :is="event.icon" class="h-5 w-5 text-white" aria-hidden="true" />
              </span>
            </div>
            <div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
              <div class="ml-2">
                <p class="text-lg text-gray-600 leading-8">
                  {{ event.content }}
                   <!-- <a :href="event.href" class="font-medium text-gray-900">{{ event.target }}</a> -->
                </p>
                <p class="text-base text-gray-400 italic">
                  {{ event.target }}
                </p>
                <p v-if="event.description" class="text-base text-gray-500 mt-1 mr-0 lg:mr-24">
                  {{ event.description }}
                </p>
              </div>
              <div class="text-right text-base whitespace-nowrap text-gray-500">
                <time :datetime="event.datetime">{{ event.date }}</time>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </section>
</template>

<script>
import { BriefcaseIcon, BookOpenIcon, AcademicCapIcon, ClockIcon } from '@heroicons/vue/outline';

const timeline = [
  // Today
  {
    id: 15,
    content: 'Oggi',
    target: "",
    href: '#',
    date: 'Oggi',
    datetime: '2022-10-01',
    icon: ClockIcon,
    iconBackground: 'bg-gray-300',
  },
  {
    id: 14,
    content: 'Senior Software Engineer',
    target: "KeyPartner",
    description: '',
    href: '#',
    date: 'Gen 2023',
    datetime: '2023-01-01',
    icon: BriefcaseIcon,
    iconBackground: 'bg-yellow-400',
  },
  // Borsa eventi culturali
  {
    id: 13,
    content: 'Front-end Developer',
    target: "Università degli Studi dell'Aquila",
    description: 'Vincitore della borsa "Studio, progettazione e sviluppo di una applicazione mobile per la gestione di eventi culturali"',
    href: '#',
    date: 'Nov 2022',
    datetime: '2022-11-01',
    icon: BriefcaseIcon,
    iconBackground: 'bg-yellow-400',
  },
  // Magistrale
  {
    id: 12,
    content: 'Conseguimento laurea magistrale',
    target: '110/110 con lode',
    href: '#',
    date: '2022',
    datetime: '2022-10-22',
    icon: AcademicCapIcon,
    iconBackground: 'bg-green-400',
  },
  {
    id: 11,
    content: 'Software Engineer',
    target: "KeyPartner",
    href: '#',
    date: 'Giu 2022',
    datetime: '2022-06-20',
    icon: BriefcaseIcon,
    iconBackground: 'bg-yellow-400',
  },
  {
    id: 10,
    content: 'Docente ReactJS/React Native',
    target: "Istituto Europeo Terzo Millennio",
    href: '#',
    date: 'Mar 2022',
    datetime: '2022-03-11',
    icon: BriefcaseIcon,
    iconBackground: 'bg-yellow-400',
  },
  // Borsa evoluzione app UnivAQ
  {
    id: 9,
    content: 'Front-end Developer',
    target: "Università degli Studi dell'Aquila",
    description: 'Vincitore della borsa "Evoluzione dell\'app "UnivAQ""',
    href: '#',
    date: 'Ott 2021',
    datetime: '2021-10-01',
    icon: BriefcaseIcon,
    iconBackground: 'bg-yellow-400',
  },
  // Magistrale
  {
    id: 8,
    content: 'Laurea magistrale in informatica (NeDaS)',
    target: "Università degli Studi dell'Aquila",
    href: '#',
    date: 'Sett 2020',
    datetime: '2020-09-01',
    icon: BookOpenIcon,
    iconBackground: 'bg-gray-300',
  },
  // Borsa app UnivAQ
  {
    id: 7,
    content: 'Front-end Developer',
    target: "Università degli Studi dell'Aquila",
    description: 'Vincitore della borsa "Borse di studio per la collaborazione nello sviluppo di un\'applicativo a supporto della comunicazione, nonché per migliorare la fruibilità dei servizi di Ateneo."',
    href: '#',
    date: 'Set 2020',
    datetime: '2020-09-01',
    icon: BriefcaseIcon,
    iconBackground: 'bg-yellow-400',
  },
  // Master
  {
    id: 5,
    content: 'Conseguimento master',
    target: '',
    href: '#',
    date: '2020',
    datetime: '2021-16-02',
    icon: AcademicCapIcon,
    iconBackground: 'bg-green-400',
  },
  {
    id: 6,
    content: 'Master in Mobile and Web Technologies',
    target: "Università degli Studi dell'Aquila",
    href: '#',
    date: 'Sett 2019',
    datetime: '2019-09-01',
    icon: BookOpenIcon,
    iconBackground: 'bg-gray-300',
  },
  // Borsa poli museali
  {
    id: 5,
    content: 'Developer',
    target: "Università degli Studi dell'Aquila",
    description: 'Vincitore della borsa "Studio ed Analisi di Tecnologie ed Algoritmi di accesso ai Poli Museali".',
    href: '#',
    date: 'Dic 2019',
    datetime: '2019-12-01',
    icon: BriefcaseIcon,
    iconBackground: 'bg-yellow-400',
  },
  // Triennale
  {
    id: 4,
    content: 'Conseguimento laurea triennale',
    target: '105/110',
    href: '#',
    date: '2018',
    datetime: '2019-03-23',
    icon: AcademicCapIcon,
    iconBackground: 'bg-green-400',
  },
  // Tesi
  {
    id: 3,
    content: 'Tesista e successivamente developer',
    target: 'nExpecto s.r.l.',
    description: 'Titolo tesi: "Sistema biometrico per la validazione di identità basato sul riconoscimento dei volti e delle impronte digitali"',
    href: '#',
    date: 'Apr 2018',
    datetime: '2018-03-01',
    icon: BriefcaseIcon,
    iconBackground: 'bg-yellow-400',
  },
  {
    id: 2,
    content: 'Laurea triennale in informatica',
    target: "Università degli Studi dell'Aquila",
    href: '#',
    date: 'Sett 2015',
    datetime: '2015-09-01',
    icon: BookOpenIcon,
    iconBackground: 'bg-gray-300',
  },
  // Diploma
  {
    id: 1,
    content: 'Conseguimento diploma',
    target: '',
    href: '#',
    date: '2015',
    datetime: '2015-07-01',
    icon: AcademicCapIcon,
    iconBackground: 'bg-green-400',
  },
  {
    id: 0,
    content: 'Liceo scientifico',
    target: 'Adone Zoli',
    href: '#',
    date: 'Sett 2010',
    datetime: '2010-09-01',
    icon: BookOpenIcon,
    iconBackground: 'bg-gray-300',
  },
  
]

export default {
  name: 'Experience',
  setup() {
    return {
      timeline,
    }
  },
}
</script>