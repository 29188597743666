<template>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 122.88 84.13" style="enable-background:new 0 0 122.88 84.13" xml:space="preserve"><g><path class="st0" d="M122.39,39.31c-0.88-0.88-12.12-15.04-14.02-17.38c-2.04-2.34-2.92-1.9-4.24-1.75 c-1.31,0.15-15.48,2.63-17.09,2.77c-1.61,0.29-2.63,0.88-1.61,2.34c0.88,1.31,10.22,14.46,12.27,17.53l-37.24,8.91L30.96,2.21 c-1.17-1.75-1.46-2.34-4.09-2.19c-2.63,0.15-23.22,1.9-24.68,1.9C0.72,2.07-0.88,2.65,0.58,6.16C2.04,9.66,25.41,59.9,25.99,61.36 c0.58,1.46,2.34,3.8,6.28,2.92c4.09-1.02,18.11-4.67,25.85-6.72c4.09,7.3,12.27,22.2,13.88,24.39c2.04,2.92,3.51,2.34,6.57,1.46 c2.48-0.73,38.27-13.58,39.87-14.31c1.61-0.73,2.63-1.17,1.46-2.78c-0.88-1.17-10.22-13.88-15.19-20.45 c3.36-0.88,15.48-4.09,16.8-4.53C122.97,40.92,123.26,40.19,122.39,39.31L122.39,39.31L122.39,39.31z M54.76,53.19 c-0.44,0.15-21.32,5.11-22.35,5.4c-1.17,0.29-1.17,0.15-1.17-0.29C30.96,57.86,6.42,7.03,5.98,6.45c-0.29-0.58-0.29-1.17,0-1.17 c0.29,0,19.72-1.75,20.3-1.75c0.73,0,0.58,0.15,0.88,0.58c0,0,27.31,47.18,27.75,47.91C55.49,52.75,55.2,53.04,54.76,53.19 L54.76,53.19L54.76,53.19z M113.48,64.14c0.29,0.58,0.73,0.88-0.44,1.17c-1.02,0.44-35.2,11.98-35.93,12.27 c-0.73,0.29-1.17,0.44-2.04-0.88c-0.88-1.31-11.98-20.45-11.98-20.45l36.37-9.49c0.88-0.29,1.17-0.44,1.75,0.44 C101.79,48.22,113.19,63.7,113.48,64.14L113.48,64.14z M115.81,38.43c-0.88,0.15-14.17,3.51-14.17,3.51l-10.95-14.9 c-0.29-0.44-0.58-0.88,0.15-1.02c0.73-0.15,13.15-2.34,13.73-2.48c0.58-0.15,1.02-0.29,1.75,0.73 c0.73,0.88,10.08,12.85,10.52,13.29C117.27,38,116.69,38.29,115.81,38.43L115.81,38.43L115.81,38.43z"/></g></svg>
</template>

<script>
export default {
  name: 'Laravel',
  setup() {
    
  },
}
</script>

<style scoped>
  .st0{
    fill-rule:evenodd;
    clip-rule:evenodd;
    fill:#FB503B;
  }
</style>