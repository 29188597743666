<template>
  <section class="px-6 text-lg max-w-4xl mx-auto pb-12">
    <div class="relative max-w-xl mx-auto">
      <h1>
        <span class="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          Contattami
        </span>
      </h1>
      <div class="mt-12">
        <form data-netlify="true" data-netlify-honeypot="bot-field" name="contact" method="POST" @submit.prevent="handleSubmit" class="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
          <p class="hidden">
            <label>Fill if you're a bee :O<input v-model="form['bot-field']" name="bot-field" /></label>
          </p>
          <div>
            <label for="first-name" class="block text-sm font-medium text-gray-700">Nome</label>
            <div class="mt-1">
              <input v-model="form['first-name']" required type="text" name="first-name" id="first-name" autocomplete="given-name" class="py-3 px-4 block w-full shadow-sm focus:ring-green-500 focus:border-green-500 border border-gray-300 rounded-md" />
            </div>
          </div>
          <div>
            <label for="last-name" class="block text-sm font-medium text-gray-700">Cognome</label>
            <div class="mt-1">
              <input v-model="form['last-name']" required type="text" name="last-name" id="last-name" autocomplete="family-name" class="py-3 px-4 block w-full shadow-sm focus:ring-green-500 focus:border-green-500 border border-gray-300 rounded-md" />
            </div>
          </div>
          <div class="sm:col-span-2">
            <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
            <div class="mt-1">
              <input v-model="form.email" required id="email" name="email" type="email" autocomplete="email" class="py-3 px-4 block w-full shadow-sm focus:ring-green-500 focus:border-green-500 border border-gray-300 rounded-md" />
            </div>
          </div>
          <div class="sm:col-span-2">
            <label for="message" class="block text-sm font-medium text-gray-700">Messaggio</label>
            <div class="mt-1">
              <textarea v-model="form.message" required id="message" name="message" rows="4" class="py-3 px-4 block w-full shadow-sm focus:ring-green-500 focus:border-green-500 border border-gray-300 rounded-md" />
            </div>
          </div>
          <div class="sm:col-span-2">
            <button type="submit" class="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transform duration-300">
              Invia
            </button>
          </div>
        </form>
      </div>
    </div>
  </section>
  <Modal
    v-on:hideModal="hideModal"
    v-bind:isModalVisible="isModalVisible"
    v-bind:title="title"
    v-bind:message="message"
    v-bind:success="success"
  />
</template>

<script>

import Modal from '@/components/elements/Modal.vue';

export default {
  name: 'ContactMe',
  components: {
    Modal,
  },
  data: () => ({
    title: '',
    message: '',
    success: false,
    isModalVisible: false,
    form: {
      'bot-field': '',
      'first-name': '',
      'last-name': '',
      'email': '',
      'message': '',
    }
  }),
  methods: {
    // Modal
    hideModal() {
      this.isModalVisible = false;
    },
    // Form sumbission
    encode(data) {
      return Object.keys(data)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
      .join('&');
    },
    handleSubmit() {
      fetch('/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: this.encode({
          'form-name': 'contact',
          ...this.form, 
        }),
      })
      .then(() => {
          this.title = 'Messaggio inviato';
          this.message = 'Il tuo messaggio è stato inviato correttamente.';
          this.success = true;
          this.isModalVisible = true;
        })
      .catch(() => {
        this.title = 'Si è verificato un errore';
        this.message = 'Non è stato possibile inviare il tuo messaggio.';
        this.success = false;
        this.isModalVisible = true;
      });

      // new Promise((resolve, reject) => setTimeout(reject , 500))
      //   .then(() => {
      //     this.title = 'Messaggio inviato';
      //     this.message = 'Il tuo messaggio è stato inviato correttamente.';
      //     this.success = true;
      //     this.isModalVisible = true;
      //   })
      //   .catch(() => {
      //     this.title = 'Si è verificato un errore';
      //     this.message = 'Non è stato possibile inviare il tuo messaggio.';
      //     this.success = false;
      //     this.isModalVisible = true;
      //   });
    }
  }
}
</script>