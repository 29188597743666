<template>
  <section class="px-6 max-w-4xl mx-auto my-12">
    <div class="grid grid-cols-3 lg:grid-cols-5 gap-y-4 gap-x-4">
      <div
        v-for="(skill, index) in skills" 
        :key="skill.id" class="flex col-span-1 p-4 lg:p-6 m-1 lg:m-4 shadow rounded-xl transform duration-300 hover:scale-110" 
        :class="index % 2 === 0 ? '-rotate-6 hover:rotate-0' : 'rotate-6 hover:rotate-0'">
      <!-- <div
        v-for="skill in skills" 
        :key="skill.id" class="flex col-span-1 m-1 lg:m-4 p-4 transform duration-300 hover:scale-110"> -->
        <component class="m-auto" :is="skill.component" />
      </div>
    </div>
  </section>
</template>

<script>
import Javascript from '@/components/elements/svg/Javascript.vue';
import Laravel from '@/components/elements/svg/Laravel.vue';
import Python from '@/components/elements/svg/Python.vue';
import React from '@/components/elements/svg/React.vue';
import Vue from '@/components/elements/svg/Vue.vue';

const skills = [
  {id: 0, component: Javascript},
  {id: 1, component: Laravel},
  {id: 2, component: Python},
  {id: 3, component: React},
  {id: 4, component: Vue},
]

export default {
  name: 'Skills',
  setup() {
    return {
      skills,
    }
  },
}
</script>
