<template>
  <AppLayout>
    <div class="relative py-8 lg:py-16 bg-white overflow-hidden">
      <!-- <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
        <div class="relative h-full text-lg max-w-4xl mx-auto" aria-hidden="true">
          <svg class="absolute top-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
            <defs>
              <pattern id="74b3fd99-0a6f-4271-bef2-e80eeafdf357" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" class="text-gray-600" fill="currentColor" />
              </pattern>
            </defs>
            <rect width="404" height="384" fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
          </svg>
          <svg class="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
            <defs>
              <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" class="text-gray-600" fill="currentColor" />
              </pattern>
            </defs>
            <rect width="404" height="384" fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
          </svg>
          <svg class="absolute bottom-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
            <defs>
              <pattern id="d3eb07ae-5182-43e6-857d-35c643af9034" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" class="text-gray-600" fill="currentColor" />
              </pattern>
            </defs>
            <rect width="404" height="384" fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
          </svg>
        </div>
      </div> -->
      <Introduction />
      <Skills />
      <!-- <Instruction />
      <Experience /> -->
      <InstructionAndExperience />
    </div>
  </AppLayout>
</template>

<script>
import AppLayout from '@/components/layouts/AppLayout.vue';
import Introduction from '@/components/elements/home/Introduction.vue';
import Skills from '@/components/elements/home/Skills.vue';
// import Instruction from '@/components/elements/home/Instruction.vue';
// import Experience from '@/components/elements/home/Experience.vue';
import InstructionAndExperience from '@/components/elements/home/InstructionAndExperience.vue';

export default {
  name: 'Home',
  components: {
    AppLayout,
    Introduction,
    Skills,
    // Instruction,
    // Experience,
    InstructionAndExperience,
  }
}
</script>
