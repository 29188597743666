<template>
  <Header />
  <main class="flex flex-col flex-grow">
    <slot />
  </main>
  <Footer />
</template>

<script>
import Header from '@/components/elements/navigation/Header.vue';
import Footer from '@/components/elements/navigation/Footer.vue';

export default {
  name: 'AppLayout',
  components: {
    Header,
    Footer,
  },
  setup() {
    
  },
}
</script>
