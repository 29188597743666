<template>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 122.88 106.42" style="enable-background:new 0 0 122.88 106.42" xml:space="preserve"><g><polygon class="st0" points="75.63,0 61.44,24.58 47.25,0 0,0 61.44,106.42 122.88,0 75.63,0"/><polygon class="st1" points="75.63,0 61.44,24.58 47.25,0 24.58,0 61.44,63.85 98.3,0 75.63,0"/></g></svg>
</template>

<script>
export default {
  name: 'Vue',
  setup() {
    
  },
}
</script>

<style scoped>
  .st0{
    fill:#4DBA87;
  } 
  
  .st1{
    fill:#425466;
  }
</style>
