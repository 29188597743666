<template>
  <header class="bg-gradient-to-r p-2">
    <nav class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8" aria-label="Top">
      <div class="w-full py-6 flex items-center justify-end border-b border-green-500 lg:border-none">
        <div class="flex items-center">
          <div class="hidden ml-10 space-x-8 lg:block">
            <router-link v-for="link in navigation" :key="link.name" :to="link.href" :class="currentRoute === link.routeName ? 'text-green-600' : 'text-gray-700'" class="text-base font-medium hover:text-green-500">
              {{ link.name }}
            </router-link>
          </div>
        </div>
      </div>
      <div class="py-4 flex flex-wrap justify-center space-x-6 lg:hidden">
        <router-link v-for="link in navigation" :key="link.name" :to="link.href" class="text-base font-medium text-black hover:text-green-600">
          {{ link.name }}
        </router-link>
      </div>
    </nav>
  </header>
</template>

<script>
import {computed} from '@vue/reactivity';
import {useRoute} from 'vue-router';

const navigation = [
  { name: 'Home', href: '/', routeName: 'Home' },
  // { name: 'About me', href: '/about' },
  { name: 'Contattami', href: '/contact', routeName: 'Contact' },
]

export default {
  name: 'Header',
  setup() {
    const currentRoute = computed(() => {
      return useRoute().name
    })

    return {
      navigation,
      currentRoute,
    }
  },
}
</script>
